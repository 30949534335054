exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bridegroom-tsx": () => import("./../../../src/pages/bridegroom.tsx" /* webpackChunkName: "component---src-pages-bridegroom-tsx" */),
  "component---src-pages-details-tsx": () => import("./../../../src/pages/details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-family-tsx": () => import("./../../../src/pages/family.tsx" /* webpackChunkName: "component---src-pages-family-tsx" */),
  "component---src-pages-firstlook-tsx": () => import("./../../../src/pages/firstlook.tsx" /* webpackChunkName: "component---src-pages-firstlook-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reception-tsx": () => import("./../../../src/pages/reception.tsx" /* webpackChunkName: "component---src-pages-reception-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */)
}

